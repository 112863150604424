<template>
  <FullScreenDialog
    v-model="dialog"
    without-padding
    title="Aziende"
    :route-father="routeFather"
  >
    <template v-slot:header-actions>
      <ExportExcelTable
        :worksheetName="worksheetName"
        :fileName="fileName"
        :fieldsToExport="fieldsToExport"
        :fetch="fetchExcel"
      > </ExportExcelTable>
      <v-btn text link @click="handleNewClick" :disabled="isLicenseBlocked">
        <v-icon class="mr-2">mdi-plus</v-icon> Nuovo
      </v-btn>
    </template>
    <template v-slot:toolbar-extension>
    
    </template>
    <template v-slot:content>
      <div class="d-flex justify-center align-center mb-3" style="width: 100%; height: 15%;">
        <AdvancedFilter
          style="width:99%; height: 100%"
          v-model="filtersValue"
          :filters="filterTypes"
          :advance-filters="advanceFilters"
          :external-filter="externalFilter"
          @apply-filters="applyFilters"
        >
          <template v-slot:chip-custom-name="{filter}">
            Descrizione: {{ filter.value }}
          </template>

          <template v-slot:custom-paymentMethods="{filter}">
            <v-row>
              <v-col class="pa-4">
                <v-autocomplete
                  v-model="filter.value"
                  :items="paymentMethodsItems"
                  dense
                  hide-details="auto"
                  label="Modalità di pagamento"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </template>
        </AdvancedFilter>
      </div>
      <div style="height: 83%; overflow-y: hidden" ref="dataTableWrapper">
        <TypeDataTable
          v-model="selected"
          loading-text="Caricamento fornitori"
          :headers="headers"
          :loading="loading"
          :items="filteredSuppliers"
          :translator="translator"
          @edit="handleEditClick"
          @delete="handleDelete"
          :page.sync="page"
          :row-per-page.sync="rowPerPage"
          :total-pages.sync="totalPages"
          :height="dataTableHeight"
          :show-select="false"
          @dblclick:row="handleEditDoubleClick"
          :sort-by="'businessName'"
        ></TypeDataTable>
      </div>
      <StandardDialog
        v-model="showBarcode"
        :title="titleDelete"
        :dialog-height="null"
        dialog-max-width="500px"
        persistent
      >
        <ManualBarcodeInput
          v-model="barcodePermission"
          @confirm="deletePermission"
        >
        </ManualBarcodeInput>
      </StandardDialog>
    </template>

  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from '@/components/common/FullScreenDialog.vue'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import suppliersService from '@/services/suppliers/suppliers.service.js'
import suppliesNameMapping from '@/services/suppliers/suppliesNameMappings.js'
import paymentMethodsNameMapping from '@/services/suppliers/paymentMethodsNameMappings.js'
import StandardMenu from "@/components/common/StandardMenu.vue";
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import ExportExcelTable from "@/components/common/ExportExcelTable.vue";
import StandardDialog from '@/components/common/StandardDialog.vue';
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import dateUtils from "@/mixins/common/dateUtils"
import operatorService from '@/services/operators/operators.service.js';


export default {
  name: "SuppliersRegistryList",
  components: {
    FullScreenDialog,
    AdvancedFilter,
    TypeDataTable,
    StandardMenu,
    StandardDialog,
    ManualBarcodeInput,
    ExportExcelTable
  },
  mixins:[dateUtils],
  data: function() {
    let props = this.$router.resolve({name: 'Registries'});

    return {
      dialog: true,
      loadingItems: false,
      loadingHeaders: false,
      routeFather: props.href,
      selected: undefined,
      dataTableHeightValue: 400,
      suppliers: [],
      headers: [],
      page: 1,
      rowPerPage: 100,
      totalPages: 100,
      resizeListener: undefined,
      searchText: undefined,
      paymentMethodsItems: [],
      filterTypes: [
        { type: 'string', operator: 'like', field: 'city', name: 'Località', label: 'Località', color: "", value: undefined, icon: 'mdi-city' },
        { type: 'string', operator: 'like', field: 'address', name: 'Indirizzo', label: 'Indirizzo', color: "", value: undefined, icon: 'mdi-map-marker' },
        { type: 'string', operator: 'like', field: 'VATNumber', name: 'Partita IVA', label: 'Partita IVA', color: "", value: undefined },
        { type: 'string', operator: 'like', field: 'fiscalCode', name: 'Codice Fiscale', label: 'Codice Fiscale', color: "", value: undefined },
        { type: 'string', operator: 'like', field: 'notes', name: 'Note', label: 'Note', color: "", value: undefined, icon: 'mdi-note-text' },
        { type: 'custom', operator: 'custom', field: 'paymentMethods', name: 'Metodi di Pagamento', label: 'Metodi di Pagamento', color: "", value: undefined, icon: 'mdi-cash' },
      ],
      advanceFilters: [
        { type: 'string', operator: 'like', field: 'cellphone', name: 'Cellulare', label: 'Cellulare', color: "", value: undefined, icon: 'mdi-cellphone' },
        { type: 'string', operator: 'like', field: 'telephone', name: 'Telefono', label: 'Telefono', color: "", value: undefined, icon: 'mdi-phone-classic' },
        { type: 'string', operator: 'like', field: 'email', name: 'E-Mail', label: 'E-Mail', color: "", value: undefined, icon: 'mdi-email' },
      ],
      externalFilter: { type: 'custom', operator: 'like', field: 'businessName', name: 'Nome', label: 'Regione Sociale', color: "", value: undefined },
      filtersValue: [
      ],
      worksheetName: "",
      fileName:"",
      fieldsToExport: {},
      barcodePermission: undefined,
      showBarcode: false,
      selectedDeleteSupplier: undefined,
      isLicenseBlocked: undefined,
    }
  },
  props: {
    filters: {
      type: Array,
      default: function() {
        return [
        ]
      }
    }
  },
  mounted: function() {
    this.fetchHeaders()
    this.filtersValue = this.filters
    this.fetchSuppliers()

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight * 0.9

    this.resizeListener = function() {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight * 0.9
    }
    this.resizeListener = this.resizeListener.bind(this)
    window.addEventListener('resize', this.resizeListener)

    this.fieldsToExport = suppliersService._fieldsToExport();
    this.worksheetName = "Export Aziende ";
    this.fileName =
      "Aziende_" + this._buildDateToExport(new Date()) +
      ".xls";

    paymentMethodsNameMapping.options().then((options) => {
      this.paymentMethodsItems = options
    })
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    fetchSuppliers() {
      this.loadingItems = true
      let filters = [...this.filtersValue]

      suppliersService.list(this.page, this.rowPerPage, filters).then((results) => {
        if(this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage
        }

        if(this.page != results.page) {
          this.page = results.page
        }

        this.totalPages = results.totalPages
        if(this.totalPages < this.page) {
          this.page = this.totalPages
        }

        this.suppliers = results.rows
        this.loadingItems = false
      })
    },
    fetchHeaders() {
      this.loadingHeaders = true
      suppliersService.fields().then((headers) => {
        this.headers = headers
        this.loadingHeaders = false
      })
    },
    handleEditClick(supplier) {
      this.$router.push({
        name: 'SuppliersRegistryEditFormTab', 
        params: {
          id: supplier.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'SuppliersRegistryList'}).href,
          pathName: 'SuppliersRegistryList'
        }
      })
    },
    handleEditDoubleClick(row, {item: supplier}) {
      this.$router.push({
        name: 'SuppliersRegistryEditFormTab', 
        params: {
          id: supplier.id,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({name: 'SuppliersRegistryList'}).href,
          pathName: 'SuppliersRegistryList'
        }
      })
    },
    handleNewClick() {
      this.$router.push({
        name: 'SuppliersRegistryNewFormTab', 
        query: {
          pathToGoBack: this.$router.resolve({name: 'SuppliersRegistryList'}).href
        }
      })
    },
    translator(fieldName, fieldValue) {
      if(fieldName == 'supplies') {
        return suppliesNameMapping.getHumanName(fieldValue)
      } else if(fieldName == 'paymentMethods') {
        return paymentMethodsNameMapping.getHumanName(fieldValue)
      }
    },
    deletePermission() {
      operatorService.canPerformOperation(this.barcodePermission, "Registries", "delete").then((result) => {
        if (result) {
          this.loading = true
          suppliersService.archive(this.selectedDeleteSupplier).then(() => {
            this.fetchSuppliers()
            this.loading = false
          })
        } else {
          alert("Non hai i permessi per eseguire questa azione!")   
        }
      }).catch((error) => {
        console.log(error)
        alert("Badge non riconosciuto")
      })
      this.showBarcode = false
    },
    handleDelete(supplier) {
      this.selectedDeleteSupplier = supplier
      this.barcodePermission = undefined
      this.showBarcode = true
    },
    applyFilters(filters) {
      this.currentFilters = filters
      this.fetchSuppliers(this.currentFilters)
    },
    async fetchExcel(){
      let filters = [...this.filtersValue];

      let suppliers = (await suppliersService.list(undefined, 15000, filters)).rows

      return suppliers
    },
  },
  computed: {
    filteredSuppliers() {
      return this.suppliers
    },
    loading() {
      return this.loadingItems || this.loadingHeaders
    },
    dataTableHeight() {
      return this.dataTableHeightValue + 'px'
    },
    titleDelete() {
      if (this.selectedDeleteSupplier)
        return "Elimina " + this.selectedDeleteSupplier.businessName
      else 
        return "Elimina Azienda"
    },
  },
  watch: {
    page() {
      this.fetchSuppliers(this.currentFilters)
    },
    rowPerPage() {
      this.fetchSuppliers(this.currentFilters)
    },
  },
  isLicenseBlocked: {
    bind: "isLicenseBlocked"
  }
}
</script>

<style>

</style>